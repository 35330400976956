
import React, { useState } from 'react';
import { TextField, Button, Container, Typography,  } from '@mui/material';
import MotionHoc from "./MotionHoc";
import '../assets/css/style.css'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import {  useNavigate } from 'react-router-dom'


const jsonData = [
  { id: 1, label: 'Nasya Org',  },
  { id: 2, label: 'Nasya',  },
  { id: 3, label: 'Lannister',  },
  { id: 4, label: 'Stark',  },
  { id: 5, label: 'Targaryen',  },
  { id: 6, label: 'Melisandre',  },
  { id: 7, label: 'Clifford',  },
  { id: 7, label: 'Frances',  },
  { id: 7, label: 'Roxie',  },
];
const FormComponent = () => {
  const [formData, setFormData] = useState({
    groupid: '',
    groupName: '',
    groupDesc:""
  });
  const navigate = useNavigate()

  const [filterTerm, setFilterTerm] = useState('');





  const handleChange = (e) => {
    // debugger;
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const filteredData = jsonData.filter(item =>
    item.label.toLowerCase().includes(filterTerm.toLowerCase())
  );

  const handleFilterChange = (event) => {
    setFilterTerm(event.target.value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to backend
    console.log('Form submitted:', formData);
  };
  const handleClick = (e) => {
    navigate('/groupTable')
  }
  return (
    <Container >
      <Typography variant="h4" align="center" gutterBottom>
        Org Management
      </Typography>

        <div class="box">
          <div class="three">
            <div className='image-label3'>
              <TextField
                type="text"
                placeholder="Search..."
                value={filterTerm}
                onChange={handleFilterChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
              {filteredData.map(item => (
                <FormControlLabel className='filterTableClass'
                  key={item.id}
                  control={<Checkbox defaultChecked={item.defaultChecked} disabled={item.disabled} />}
                  label={item.label}
                  required={item.required}
                />
              ))}
            </div>
 
          </div>
          <div class="one" >
            <div className='image-label'>

              <form onSubmit={handleSubmit}>
                <TextField
                  label="Group Id"
                  name="groupid"
                  value={formData.groupid}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="groupName"
                  name="groupName"
                  value={formData.groupName}
                  onChange={handleChange}
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={4}
                  sx={{ minWidth: 795 }}
                  margin="normal"
                // variant="outlined" // Uncomment this line if you want outlined appearance
                />
                       <TextField
                  label="groupDesc"
                  name="groupDesc"
                  value={formData.groupDesc}
                  onChange={handleChange}
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={4}
                  sx={{ minWidth: 795 }}
                  margin="normal"
                // variant="outlined" // Uncomment this line if you want outlined appearance
                />
              
                <div class="box">
                  <div class="one">
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                      Cancel
                    </Button>
                  </div>

                  <div class="two">
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                      Save
                    </Button>
                  </div>

                  <div class="three">
                  <Button type="submit" variant="contained" color="primary" onClick={handleClick} fullWidth>view 
                  </Button>
                  </div>
                </div>


              </form>
            </div>

          </div>
         

        </div>

    </Container>
  );
};

const Group = MotionHoc(FormComponent);

export default Group;
