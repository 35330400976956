import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

const backendURL = 'http://13.127.13.91:5000/'

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      let email=username
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${backendURL}api/user/login`,
        { email, password },
        config
      )

      // store user's token in local storage
      localStorage.setItem('userToken', data.userToken)

      return data
    } catch (error) {
      console.log(error,'llllllllllllllllllllllll')
      // return custom error message from API if any
      if (error.response && error.response.data.error_message) {
        return rejectWithValue(error.response.data.error_message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const registerUser = createAsyncThunk(
  'createOrg',
  async ({ orgName,image,email,password, orgDesc, role }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      let responseData=await axios.post(
        `${backendURL}createOrg`,
        { orgName,image ,email,password, orgDesc, role},
        config
      )
      if(responseData&&responseData.data&&responseData.data.success){
        return {success:true,message:"Organization created successfully."}
      }else{
        return {success:false,message:"Organization creation failed."}
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

/*
export const readOrg = createAsyncThunk(
  'readOrg',
  async ({ orgId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      let responseData=await axios.post(
        `${backendURL}createOrg`,
        { orgName,image ,email,password, orgDesc, role},
        config
      )
      if(responseData&&responseData.data&&responseData.data.success){
        return {success:true,message:"Organization created successfully."}
      }else{
        return {success:false,message:"Organization creation failed."}
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)*/
