import { Route, Routes, useLocation ,Navigate} from "react-router";
import './App.css'
import Home from "./Pages/Home";
import Team from "./Pages/Team";
import Calender from "./Pages/Calender";
import Documents from "./Pages/Documents";
import Projects from "./Pages/Projects";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import ProtectedRoute from './routing/ProtectedRoute'
import LoginPage from "./Pages/LoginPage";
import Sidebar from "./Sidebar";
import Organization from "./Pages/Organization";
import OrgUser from "./Pages/orgTable";
import Group from "./Pages/Group";
import GroupTable from "./Pages/GroupTable";

const Pages = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: calc(2rem + 2vw);
    background: linear-gradient(to right, #803bec 30%, #1b1b1b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

function App() {
  const location = useLocation();
  
  return (
    <>
     {localStorage.getItem('userToken') &&<Sidebar />}
      <Pages>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route  path='/login' element={<LoginPage/>} />
            <Route element={<ProtectedRoute />}>
              <Route  exact path="/" element={<Home />} />
              <Route path='/user-profile' element={<Home />} />
              <Route path="/team" element={<Team />} />
              <Route path="/calender" element={<Calender />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/org" element={<Organization />} />
              <Route path="/orgUser" element={<OrgUser />} />
              <Route path="/group" element={<Group />} />
              <Route path="/groupTable" element={<GroupTable />} />
            </Route>
            <Route path='*' element={<Navigate to='/' replace />} />
          </Routes>
        </AnimatePresence>
      </Pages>
    </>
  );
}

export default App;
