import authLayout from "../hoc/authLayout";
import { useForm } from 'react-hook-form'
import { useNavigate,Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../features/auth/authActions'
import { useEffect } from 'react'
import Error from '../component/Error'
import Spinner from '../component/Spinner'

const LoginPage = () => {
  const { loading, userInfo, error } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const { register, handleSubmit } = useForm()

  const navigate = useNavigate()

  // redirect authenticated user to profile screen
  useEffect(() => {
    if (userInfo) {
      navigate('/user-profile')
    }
  }, [navigate, userInfo])

  const submitForm = (data) => {
    dispatch(userLogin(data))
  }
    return (
        <div>
            <form className="login-form" onSubmit={handleSubmit(submitForm)}>
            {error && <Error>{error}</Error>}
                <div className="d-flex align-items-center my-4">
                     <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
                 </div>
                 {/* <!-- Email input --> */}
                 <div className="form-outline mb-4">
                     <label className="form-label" htmlFor="form3Example3">Email address</label>
                     <input type="email" name="email"   {...register('username')}  id="form3Example3" className="form-control form-control-lg"
                     placeholder="Enter a valid email address"  />
                 </div>
                 {/* <!-- Password input --> */}
                 <div className="form-outline mb-3">
                     <label className="form-label" htmlFor="form3Example4">Password</label>
                     <input type="password" name="password"     {...register('password')} id="form3Example4" className="form-control form-control-lg"
                     placeholder="Enter password" />
                 </div>
                 <div className="d-flex justify-content-between align-items-center">
                     {/* <!-- Checkbox --> */}
                     <div className="form-check mb-0">
                     <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                     <label className="form-check-label" htmlFor="form2Example3">
                         Remember me
                     </label>
                     </div>
                     <Link to="/reset-password" className="text-body">Forgot password?</Link>
                 </div>
                 <div className="text-center text-lg-start mt-4 pt-2">
                    {/* <Link to="/" type="button" className="btn btn-primary btn-lg" disabled={loading}>   {loading ? <Spinner /> : 'Login'}</Link> */}
                    <button type='submit' className='button btn btn-primary btn-lg' disabled={loading}>
                      {loading ? <Spinner /> : 'Login'}
                    </button>
                     <p className="small fw-bold mt-2 pt-1 mb-0">Don't have an account? <a href="#!"
                         className="link-danger">Register</a></p>
                 </div>
             </form>
       </div>
    );
}

export default authLayout(LoginPage);