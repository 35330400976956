
import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Input, Paper, IconButton, } from '@mui/material';
import MotionHoc from "./MotionHoc";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PhotoCamera } from '@mui/icons-material';
import '../assets/css/style.css'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { registerUser } from '../features/auth/authActions'
import Error from '../component/Error'
import { toast } from 'react-toastify';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const jsonData = [
  { id: 1, label: 'Nasya Org', },
  { id: 2, label: 'Nasya', },
  { id: 3, label: 'Lannister', },
  { id: 4, label: 'Stark', },
  { id: 5, label: 'Targaryen', },
  { id: 6, label: 'Melisandre', },
  { id: 7, label: 'Clifford', },
  { id: 8, label: 'Frances', },
  { id: 9, label: 'Roxie', },
];
const FormComponent = () => {
  const [formData1, setFormData] = useState({
    orgName: '',
    orgDesc: '',
    email: '',
    password: '',
    role: '',
    image: ''
  });
  const { loading, orgInfo, error } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false);
  const [filterTerm, setFilterTerm] = useState('');

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({
          ...formData1,
          image: event.target.result // Assigning the image data to the formData state
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    // debugger;
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const filteredData = jsonData.filter(item =>
    item.label.toLowerCase().includes(filterTerm.toLowerCase())
  );

  const handleFilterChange = (event) => {
    setFilterTerm(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('orgName', formData1.orgName);
    formData.append('orgDesc', formData1.orgDesc);
    formData.append('email', formData1.email);
    formData.append('password', formData1.password);
    formData.append('role', formData1.role);
    formData.append('image', formData1.image);

    // Convert FormData to JSON object
    const jsonObject = {};
    formData.forEach((value, key) => {
      jsonObject[key] = value;
    });
    // dispatch(registerUser(jsonObject))
    dispatch(registerUser(jsonObject)).then((response) => {
      if (response && response.payload && response.payload.success) {
        // Clear formData1
        setFormData({
          orgName: '',
          orgDesc: '',
          email: '',
          password: '',
          role: '',
          image: null,
        });
        NotificationManager.success('Success message', 'Title here');

      } else {
        toast.error("Failed to register user. Please try again.");
      }
    }).catch((error) => {
      console.error("Error occurred during registration:", error);
      toast.error("An error occurred. Please try again later.");
    });

    // console.log(jsonObject,'json object ')
    /*for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }*/
  };

  const handleClick = (e) => {
    navigate('/orgUser')
  }
  return (
    <Container >
       <NotificationContainer/>
      <Typography variant="h4" align="center" gutterBottom>
        Org Management
      </Typography>

      <div class="box">
        <div class="three">
          <div className='image-label3'>
            <TextField
              type="text"
              placeholder="Search..."
              value={filterTerm}
              onChange={handleFilterChange}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
            {filteredData.map(item => (
              <FormControlLabel className='filterTableClass'
                key={item.id}
                control={<Checkbox defaultChecked={item.defaultChecked} disabled={item.disabled} />}
                label={item.label}
                required={item.required}
              />
            ))}
          </div>

        </div>
        <div class="one" >
          <div className='image-label'>

            <form onSubmit={handleSubmit}>
              {error && <Error>{error}</Error>}
              <TextField
                label="Org Name"
                name="orgName"
                value={formData1.orgName}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />

              <TextField
                label="orgDesc"
                name="orgDesc"
                value={formData1.orgDesc}
                onChange={handleChange}
                id="outlined-multiline-flexible"
                multiline
                maxRows={4}
                sx={{ minWidth: 595 }}
           
              />
              <TextField
                type="email"
                label="Email"
                name="email"
                value={formData1.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                type="password"
                label="Password"
                name="password"
                value={formData1.password}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <FormControl sx={{ minWidth: 595 }}>
                <InputLabel id="demo-controlled-open-select-label">Role</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={formData1.role}
                  label="Role"
                  name="role"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'org_admin'}>Org Admin</MenuItem>
                  {/* <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <div class="box">
                <div class="one">
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Cancel
                  </Button>
                </div>

                <div class="two">
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Save
                  </Button>
                </div>

                <div class="three">
                  <Button type="submit" variant="contained" color="primary" onClick={handleClick} fullWidth>view
                  </Button>
                </div>
              </div>


            </form>
          </div>

        </div>
        <div class="two">
          <div className='image-label'>


            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <label htmlFor="image-upload-input">
                <Input
                  id="image-upload-input"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
                <IconButton color="primary" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
              <Paper style={{ width: 200, height: 200, overflow: 'hidden' }}>
                {formData1.image && (
                  <img
                    src={formData1.image}
                    alt="Uploaded"
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                  />
                )}
              </Paper>
            </div>
          </div>

        </div>

      </div>

    </Container>
  );
};

const Org = MotionHoc(FormComponent);

export default Org;
