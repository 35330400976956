import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import MotionHoc from "./MotionHoc";
import {  Container, Typography, } from '@mui/material';
import { Button } from '@mui/material';


const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Grop Name', width: 150 },
    { field: 'desc', headerName: 'Group Desc.', width: 150 },
    // { field: 'email', headerName: 'Email Id', width: 150 },
    // { field: 'role', headerName: 'Role', width: 150 },
    // { field: 'status', headerName: 'Status', width: 150 },
    {
      field: 'Add',
      headerName: 'Add',
      width: 100,
      renderCell: (params) => (
        <strong>
          <Button variant="contained" color="primary" size="small">
            Add User
          </Button>
        </strong>
      ),
    },
    {
        field: 'edit',
        headerName: 'Edit',
        width: 100,
        renderCell: (params) => (
          <strong>
            <Button variant="contained" color="primary" size="small">
              Edit
            </Button>
          </strong>
        ),
      },
      {
        field: 'delete',
        headerName: 'Delete',
        width: 100,
        renderCell: (params) => (
          <strong>
            <Button variant="contained" color="secondary" size="small">
              Delete
            </Button>
          </strong>
        ),
      },
];

const rows = [
    { id: 1, name: 'Nasya Org', desc: 'Jon', email: "ranjan@gmail.com",role:'Super Admin',status:'Active' },
    { id: 2, name: 'Nasya', desc: 'desc...', email: 'rkr@gmail.com',role:'Super Admin',status:'Active'  },
    { id: 3, name: 'Lannister', desc: 'Jaime', email: "ranjan@gmail.com",role:'Super Admin',status:'Active'  },
    { id: 4, name: 'Stark', desc: 'Arya', email: "ranjan@gmail.com" ,role:'Super Admin',status:'Active' },
    { id: 5, name: 'Targaryen', desc: 'Daenerys', email: "ranjan@gmail.com",role:'Super Admin',status:'Active'  },
    { id: 6, name: 'Melisandre', desc: null, email: "ranjan@gmail.com",role:'Super Admin',status:'Active'  },
    { id: 7, name: 'Clifford', desc: 'Ferrara', email: "ranjan@gmail.com",role:'Super Admin',status:'Active'  },
    { id: 8, name: 'Frances', desc: 'Rossini', email: "ranjan@gmail.com",role:'Super Admin',status:'Active'  },
    { id: 9, name: 'Roxie', desc: 'Harvey', email: "ranjan@gmail.com",role:'Super Admin',status:'Active'  },
];

function DataTable() {


    return (

        <Container >
              <Typography variant="h4" align="center" gutterBottom>
              View Org User
            </Typography>
            <div style={{ height: 600, width: '100%' }} className='image-label'>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
        </Container>
    );
}



const GroupTable = MotionHoc(DataTable);

export default GroupTable;