import { useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router-dom'


const ProtectedRoute = () => {
  const { userInfo } = useSelector((state) => state.auth)
  // show unauthorized screen if no user is found in redux store
  if (!userInfo) {
    if(localStorage.getItem('userToken')){
      return(<NavLink to='/user-profile'></NavLink>)
      // return  <Sidebar />
    }else{
      return (
        <div className='unauthorized'>
          <h1>Unauthorized :(</h1>
          <span>
            <NavLink to='/login'>Login</NavLink> to gain access
          </span>
        </div>
      )
    }
  
  }

  return <Outlet />
}

export default ProtectedRoute
